







import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class Quote extends Vue {
    
    @Prop()
    public quote: any;

    private get quoteText() {
        var qBy = this.quoteBy();
        if(qBy != null)
            return (this.quote.quote as string).replace(/['"]+/g, '').replace(qBy, "").trim();
        
        return (this.quote.quote as string).replace(/['"]+/g, '').trim();
    }

    private quoteBy() {
        var quote: string = (this.quote.quote as string);
        var targs = quote.split('-');
        if(targs.length > 1) {
            var last = targs[targs.length-1].trim();
            if(!(last.indexOf('"') >= 0))
                return "-"+last;
        }
        return null;
    }

}
