










import { Vue, Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { Spinner } from '@components/UI';

import { Quote } from './components';

@Component({
    components: { Spinner, Quote }
})
export default class QuotesView extends Vue {
    
    @Action
    public loadQuotes: () => Promise<any>;

    @Getter
    public quotesStream;

    private observer: IntersectionObserver | null;
    private loading: boolean = false;

    public mounted() {
        this.observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting && !this.loading) {
                    this.loading = true;
                    this.loadQuotes().finally(() => this.loading = false);
                }
            })
        });

        this.observer.observe(this.$refs.trigger as HTMLElement);
    }

    public beforeDestroy() {
        if (this.observer) {
            this.observer.unobserve(this.$refs.trigger as HTMLElement);
            this.observer.disconnect();
            this.observer = null;
        }
    }

}
